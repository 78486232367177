import { createClient } from "../../prismicio";
import { useState, useEffect } from "react";
import Select from "@/components/ContactForm/Select/Select";
import { Button } from "@/components";
import courseCertExpiration from "@/Utility/courseCertExpiration";

const StudentAgreementForm = ({ setSignStep }) => {
	const [activeCourses, setActiveCourses] = useState([]);
	const [selectedCourse, setSelectedCourse] = useState("");

	const transformCourseName = (courseName) =>
		courseName
			.toLowerCase() // Convert to lowercase
			.replace(/[^a-z0-9\s]/g, "") // Remove special characters
			.replace(/\s+/g, "_"); // Replace spaces with underscores

	const handleSubmit = async (event) => {
		event.preventDefault();

		const formData = new FormData(event.target);
		const formValues = Object.fromEntries(formData.entries());

		// Find selected course object from activeCourses
		const selectedCourseData = activeCourses.find(
			(course) => course.courseName === formValues.course
		);

		// Find matching course in Utility/courseCertExpiration.js to get certification expiration length (.years)
		const courseCertExpirationTime = courseCertExpiration({
			id: selectedCourseData?.templateId,
		});

		// Create cert expiration date from certification date and years map in Utility/courseCertExpiration.js
		const certExpirationDate = (certDate, years) => {
			const certDateObj = new Date(certDate);
			certDateObj.setFullYear(certDateObj.getFullYear() + years);
			return certDateObj;
		};

		// Set dynamic property name for course and the certification date
		formValues[`${transformCourseName(formValues.course)}_cert_date`] =
			selectedCourseData?.endDate;

		// Set dynamic property name for course and the certification end date
		formValues[
			`${transformCourseName(formValues.course)}_cert_expiration_date`
		] = certExpirationDate(
			selectedCourseData?.endDate,
			courseCertExpirationTime?.years
		);

		// Set Audience type as B2C
		formValues.audience_type = "B2C";

		// Set last_training_name as course name
		formValues.last_training_name = formValues.course;

		// Set last_training_completed_date as course end date + 1 day
		const endDate = new Date(selectedCourseData?.endDate || Date.now());
		// Set the time to midnight UTC to adhere to HS date format
		endDate.setUTCHours(0, 0, 0, 0);
		// Add one day to the endDate
		endDate.setDate(endDate.getDate() + 1);
		// Assign the adjusted date to formValues
		formValues.last_training_completed_date = endDate.toISOString();

		// Set last training years and months for email automation to linkedin cert
		// Set last training completed as course year and month
		formValues.last_training_completed_year = endDate.getFullYear();
		formValues.last_training_completed_month = endDate.getMonth() + 1;

		// Set last training completed expiration year and month
		formValues.last_training_completed_expiration_year = certExpirationDate(
			endDate,
			courseCertExpirationTime?.years || 2
		).getFullYear();

		formValues.last_training_completed_expiration_month =
			formValues.last_training_completed_month;

		// Remove the 'course' entry from formValues
		delete formValues.course;
		delete formValues.otherCourse;

		// Add the marketing contact flag
		formValues.hs_marketable_status = true;

		// Send form values to Hubspot
		const response = await fetch("/api/hubspot/postStudentAgreementContact", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ ...formValues }),
		}).catch((error) => {
			console.error("Error submitting form:", error);
			return { status: 500 }; // Simulate a failed response
		});

		// Update UI to show form signature step
		setSignStep(true);
	};

	useEffect(() => {
		fetch("/api/admin-db/get-todays-courses").then((response) => {
			response
				.json()
				.then((data) => {
					// console.log("COURSE DATA: ", data);
					setActiveCourses(data);
				})
				.catch((error) => {
					console.error("Error Fetching Course Data:", error);
				});
		});
	}, []);

	const courseOptions = activeCourses
		.map((course) => ({
			label: course.courseName,
			value: course.courseName,
		}))
		.concat({ label: "Other", value: "Other" });

	return (
		<form
			onSubmit={handleSubmit}
			className="studentAgreementForm"
			data-form="true"
			data-hs-ignore="true"
		>
			<h1>Student Training Agreement</h1>
			<div className="formRow">
				<div className="formGroup">
					<label htmlFor="first-name">First Name</label>
					<input
						id="first-name"
						name="firstname"
						type="text"
						placeholder="Enter your first name"
						required
					/>
				</div>

				<div className="formGroup">
					<label htmlFor="last-name">Last Name</label>
					<input
						id="last-name"
						name="lastname"
						type="text"
						placeholder="Enter your last name"
						required
					/>
				</div>
			</div>
			<div className="formRow">
				<div className="formGroup">
					<label htmlFor="email">Email</label>
					<input
						id="email"
						name="email"
						type="email"
						placeholder="Enter your email"
						required
					/>
				</div>
				<div className="formGroup">
					<label htmlFor="phone">Phone</label>
					<input
						id="phone"
						name="phone"
						type="tel"
						placeholder="123-456-7890"
					/>
				</div>
			</div>
			<div className="formRow">
				<div className="formGroup">
					<label htmlFor="email">Company</label>
					<input
						id="company"
						name="company"
						type="text"
						placeholder="Enter your company name"
					/>
				</div>
			</div>
			<div className="formRow">
				<Select
					label="Primary Industry"
					defaultOption="Select The Industry You Are In"
					name="primary_industry"
					containerClassName="studentAgreementCourseSelect"
					required={true}
					options={industryOptions}
				/>
				<Select
					label="Training Course"
					options={courseOptions}
					name="course"
					defaultOption="Select The Course You Are Taking"
					containerClassName="studentAgreementCourseSelect"
					required={true}
					onChange={(value) => setSelectedCourse(value)}
				/>
			</div>
			{selectedCourse === "Other" && (
				<div className="formRow">
					<div className="formGroup">
						<label htmlFor="other-course">Please specify the course</label>
						<input
							id="other-course"
							name="otherCourse"
							type="text"
							placeholder="Enter the course name"
							required
						/>
					</div>
				</div>
			)}
			<Button
				label="Sign Student Agreement"
				type="submit"
				submit={true}
				variant="primary"
			/>
		</form>
	);
};

export default StudentAgreementForm;

const industryOptions = [
	{
		label: "Construction",
		value: "Construction",
	},
	{
		label: "Utility",
		value: "Utility",
	},
	{
		label: "Solar",
		value: "Solar",
	},
	{
		label: "Wind",
		value: "Wind",
	},
	{
		label: "Telecommunications",
		value: "Telecommunications",
	},
	{
		label: "Theme Parks",
		value: "Theme Parks",
	},
	{
		label: "Arboriculture",
		value: "Arboriculture",
	},
	{
		label: "Oil & Gas",
		value: "Oil & Gas",
	},
	{
		label: "General",
		value: "General",
	},
	{
		label: "Armed Forces",
		value: "Armed Forces",
	},
	{
		label: "Window Cleaning",
		value: "Window Cleaning",
	},
	{
		label: "Bridge",
		value: "Bridge",
	},
	{
		label: "Other",
		value: "Other",
	},
];
